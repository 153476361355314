<template>
  <base-view-layout>
    <template #breadcrumb>
      <li class="breadcrumb__nav-item breadcrumb__nav-item--back">
        <router-link :to="{ name: 'Questions' }">
          <img src="@/assets/images/icons/ico-chev-left--70L.svg">
        </router-link>
      </li>
      <li class="breadcrumb__nav-item">
        <router-link :to="{ name: 'Questions' }">{{$t('newquestion.breadcrumb')}}</router-link>
      </li>
      <li class="breadcrumb__nav-item">{{$t('newquestion.breadcrumb2')}}</li>
    </template>

    <template #title>{{$t('newquestion.title')}}</template>
    <template #subtitle>{{$t('newquestion.subtitle')}}</template>

    <questions-form class="main-section" @submit-question="createQuestion($event)">
      <template #finishButton>{{$t('newquestion.btnfinish')}}</template>
    </questions-form>
  </base-view-layout>
</template>

<script>
import { inject } from 'vue'
import QuestionsForm from '@/components/QuestionsForm'
import BaseViewLayout from '@/components/BaseViewLayout'

export default {
  name: 'QuestionNew',
  components: { BaseViewLayout, QuestionsForm },
  methods: {
    async createQuestion (question) {
      question.is_public = this.$store.state.user.role_id === 1
      await this.axios.post('questions', { question })
      alert(this.$t('newquestion.alertmsg'))
      this.$router.push({ name: 'Questions' })
    }
  },
  setup () {
    const axios = inject('$axios')
    return {
      axios
    }
  }
}
</script>
